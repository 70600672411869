.app {
  position: relative;
  width: 100VW;
  height: 100VH;

  background-color: #fff;
  background-image: url('../../../public/img/734.png');
  background-size: cover;
  background-position: center;
  @media (max-width: 428px) {
    background-position: 65%;
  }
  background-repeat: no-repeat;
  color: #fff;

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(89.6deg, #17234D 0.31%, rgba(23, 35, 77, 0) 84.65%);
  }

  .textContent {
    position: absolute;
    top: 30%;
    padding: 0px 25px;
    @media (min-width: 576px) {
      padding-left: 75px;
    }
    @media (max-height: 640px) {
      top: 20%;
    }
  }
  
  .title {
    margin-top: 50px;
    font-family: "Raleway";
    font-weight: 800;
    font-size: 20px;
    width: 100%;
    height: 23px;

    @media (min-width: 576px) {
      font-size: 30px;
      height: 35px;
    }
    @media (max-height: 640px) {
      margin-top: 25px;
    }
  }
  .subContent {
    height: 96px;
    margin-top: 25px;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;

    .boldText {
      font-weight: 700;
    }

    @media (min-width: 576px) {
      font-size: 18px;
      height: 96px;
      width: 478px;
    }
  }

  .link {
    margin-top: 50px;
    position: relative;
    font-family: inherit;
    font-weight: 700;
    
    font-size: 18px;
    @media (max-height: 640px) {
      margin-top: 25px;
    }

    color: #fff;
    cursor: pointer;
  }

  .arrow {
    margin-left: 10px;
    width: 41px;
    position: absolute;
    top: 10px;
    border-top: solid 1px #fff;
    color: #fff;
  }
  .shaft {
    position: absolute;
    top: -1px;
    color: #fff;
    width: 41px;
    height: 0px;
    &::before, &::after {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #fff;
      content: "";
      width: 8px;
      height: 1px;
      transform: rotate(40deg);
      transition: all 0.5s;
      transform-origin: top right;
    }
    &::after {
      transform: rotate(-40deg);
    }
  }
}


